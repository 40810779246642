<template>
    <div class="content-box">
        <Title/>

        <Echarts/>
    </div>
</template>

<script>
export default {
    name: "industry",
    data() {
        return {
            params: {
                dataNav: [
                {
                        name: "渗透率",
                        data: "a101",
                        unit: "％"
                    },
                    {
                        name: "市场规模",
                        data: "a103",
                        unit: "亿元"
                    },
                ],
                name: '互联网家装'
            }
        }
    },
    created() {
        this.$store.dispatch('getIndustry', this.params)
    }
}
</script>